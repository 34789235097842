// Libs
import React, { useContext, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Context
import { HeaderContext } from '../../../context/header';
import { FooterContext } from '../../../context/footer';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import BreadCrumb from 'components/breadCrumb';
import DescriptionPage from 'components/contactUs/descriptionPage';
import Contact from 'components/contactUs/contact';
import Menu from 'components/contactUs/menu';
import HeaderIntern from 'components/contactUs/headerIntern';

// Styles
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto repeat(12, 70px) auto;
  column-gap: 30px;

  a,
  em {
    color: #45a7df;
  }

  em {
    font-style: normal;
  }

  code {
    margin-top: 1.375rem;
    color: #8f9194;
    font-size: unset;
    font-family: unset;
    background: unset;
  }

  @media ${device.laptop} {
    display: block;
  }
`;

const Wrapper = styled.div`
  grid-column: 2 / -2;
`;

const ContainerContent = styled.div`
  display: inline-grid;
  grid-column: 5 / 11;
  flex-direction: column;

  p {
    img {
      :nth-child(1) {
        top: 1.1875rem;
        @media ${device.laptop} {
          top: 1.1875rem;
        }
      }
    }
  }

  section {
    code {
      display: inline-block;
    }
  }

  @media ${device.laptop} {
    padding: 0 1.25rem;
    display: flex;

    p {
      width: 100%;
      font-size: 0.875rem;
      em {
        font-size: 0.875rem;
      }
    }
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false) => {
  const { fragments } = page[0];

  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else {
    return flatten(findFragment.localizations);
  }
};

const GuideCenter = ({ page, location }) => {
  const { setHasDefaultHeader, setCallCenterBackLink } = useContext(
    HeaderContext
  );

  const { setHasFooter } = useContext(FooterContext);

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setHasDefaultHeader(false);
      setCallCenterBackLink('/fale-conosco/');
      setHasFooter(false);
    }
  }, []);

  const pathname = location.pathname;

  return (
    <Layout>
      <HeaderIntern />
      <SEO dataSeo={page} />
      <GridContainer>
        <Wrapper>
          <BreadCrumb
            markdown={getMarkdown(page, 'cki96rd5c0jtu0975c7n3kacs', true)}
            marginTop
          />
        </Wrapper>
        <ContainerContent>
          <DescriptionPage
            markdown={getMarkdown(page, 'cki96wmyo0ka409759c2345pk', true)}
          />
          <Contact
            markdown={getMarkdown(page, 'cki975nt40kpa0b28ya6xk680', true)}
            marginBottom
            paddingTop
          />
          <Menu intern pathname={pathname} />
        </ContainerContent>
      </GridContainer>
    </Layout>
  );
};

export default ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              id
              pages(where: { id: "cki96rhs00ju50975u79f26ch" }) {
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return (
          <GuideCenter location={location} page={response.gcms.site.pages} />
        );
      }}
    />
  );
};
